@charset "utf-8";
//*****************共通*****************//
.icon-tel {
  &:before {
    content: '\f879';
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
  }
}

//ロード時のアニメーションチラツキ防止
.fadeUp {
  visibility: hidden;
}

.ic{
  position: relative;
  display: inline-block;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &__face{
    padding-left: 50px;
    &:before{
      width: 30px;
      height: 29px;
      background: url("/wp-content/uploads/ic_face.png") no-repeat center/contain;
    }
  }

  &__tel{
    font-size: 32px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding-left: 26px;
    &:before{
      width: 21px;
      height: 23px;
      background: url("/wp-content/uploads/ic_tel.png") no-repeat center/contain;
    }
  }

  &__tel2{
    font-size: clamp(32px, 4vw, 42px);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding-left: clamp(26px, 3.2vw, 32px);
    line-height: 1;
    display: table;
    margin-inline: auto;
    &:before{
      width: clamp(21px, 2.7vw, 27px);
      height: clamp(24px, 3vw, 30px);
      background: url("/wp-content/uploads/ic_tel2.png") no-repeat center/contain;
    }
  }

  &__mail{
    padding-top: 20px;
    &:before{
      right: 0;
      bottom: auto;
      width: 19px;
      height: 14px;
      background: url("/wp-content/uploads/ic_mail.png") no-repeat center/contain;
    }
  }

  &__mail2{
    padding-top: clamp(24px, 3vw, 30px);
    &:before{
      right: 0;
      bottom: auto;
      width: clamp(26px, 3.2vw, 32px);
      height: clamp(18px, 2.4vw, 24px);
      background: url("/wp-content/uploads/ic_mail2.png") no-repeat center/contain;
    }
  }
}

.color1{
  color: $color_primary;
}

.color2{
  color: $color_secondary;
}

.dbl{
  display: inline-block;
}

.bold{
  font-weight: bold;
}