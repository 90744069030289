@charset "utf-8";
//*****************下層*****************//
body{
  &:not(.home){
    section{
      &.bg{
        background: $color_highlight_bg;
        padding: clamp(50px, 10vw, 100px) 0;
        &:last-child{
          margin-bottom: 0;
        }

        
      }

      .row{
        &:not(:last-child){
          margin-bottom: clamp(30px, 5vw, 50px);
        }
      }

      @media (max-width: 767px) {
        .row{
          .col{
            &:not(:last-child){
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

.anchor_list {
  background: rgba($color_secondary, .1);
  padding: 1em;
  list-style: none;
  border-radius: 10px;
  li {
    margin: .5em .5em;
    a {
      padding-left: 20px;
      position: relative;
      color: $color_text;
      text-decoration: none;
      transition: all .3s;
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 8px;
        border-color: transparent transparent transparent $color_secondary;
        position: absolute;
        top: .2em;
        left: 0;
      }

      &:hover{
        color: $color_secondary;
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5em 2em;
    gap: 5px 20px;
    li{
      width: calc((100% - 20px) / 2);
      margin: 0;
    }
    &.anchor_center {
      justify-content: center;
    }
    &.col3{
      li{
        width: calc((100% - 40px) / 3);
      }
    }
  }
}

.ul_circle{
  padding: 0;
  list-style: none;
  li{
    position: relative;
    padding-left: 13px;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $color_secondary;
    }
  }

  @media (min-width: 768px){
    &.col2{
      display: flex;
      gap: 10px 2%;
      li{
        width: 49%;
      }
    }
    &.col3{
      display: flex;
      gap: 10px 2%;
      li{
        width: 32%;
      }
    }
  }
}

.box_border{
  border: 2px solid $color_primary;
  padding: 3%;
}

.box_bg{
  background: rgba($color_secondary, .1);
  padding: 4%;
  border-radius: 10px;
}

.box_bg2{
  background: rgba($color_secondary, .2);
  padding: 4%;
  border-radius: 10px;
}