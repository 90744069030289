@charset "utf-8";
.pagetop {
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 98;
  a {
    @include image-replace();
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    @media ( min-width : 768px ) {
      width: 93px;
      height: 93px;
    }
    padding: 0;
    // border-radius: 50%;
    background: $color_pagetop_bg;
    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   margin: auto;
    //   width: 12px;
    //   height: 12px;
    //   border-top: 1px solid $color_pagetop_arrow;
    //   border-left: 1px solid $color_pagetop_arrow;
    //   transform: translate(-50%, -20%) rotate(45deg);
    //   transition: .2s;
    // }
  }
}

@if $fixed_pagetop＿bottom == true {
  footer {
    &.high {
      .pagetop {
        position: absolute;
        bottom: auto;
        right: 5%;
        top: 0;
        margin: 0;
        transform: translateY(-50%);
      }
    }
  }
}