@charset "utf-8";
//*****************TOP*****************//
.sec_top{
  &.pd{
    padding: clamp(50px, 10vw, 100px) 0;
    margin-bottom: 0;
  }
  &.pdt{
    padding-top: clamp(50px, 10vw, 100px);
  }
  &.pdb{
    padding-bottom: clamp(50px, 10vw, 100px);
    margin-bottom: 0;
  }
  h2{
    font-size: clamp(26px, 3.6vw, 40px);
    line-height: 1.4;
    text-align: center;
    margin: 0 auto clamp(20px, 3vw, 35px);
    .en{
      font-weight: bold;
      font-size: 18px;
      display: table;
      margin: 1rem auto 0;
      padding-top: 1rem;
      color: $color_primary;
      font-family: Arial, Helvetica, sans-serif;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: clamp(160px, 22vw, 220px);
        height: 1px;
        background: $color_primary;
      }
    }
  }

  h3{
    margin-top: 0;
  }

  @media (min-width: 1024px){
    .l2{
      line-height: 2;
    }
  }
}

.sec_1{
  background: #f8ffc9;
  .list_feature{
    .box{
      background: white;
      box-shadow: 0 0 10px 5px rgba($color_text, .1);
      position: relative;
      padding: 2.6em 10px 10px;
      .num{
        margin: 0;
        position: absolute;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: clamp(40px, 4.6vw, 55px);
        line-height: 1;
        color: $color_primary;
        padding-top: 1rem;
        display: table;
        border-top: 2px solid;
        text-align: center;
        left: 10px;
        top: 0;
      }

      picture{
        img{
          max-width: 111px;
        }
      }

      h3{
        font-size: clamp(20px, 2.2vw, 22px);
        text-align: center;
        margin: 1em 0 5px;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 767px) {
    .list_feature{
      display: flex;
      flex-wrap: wrap;
      gap: 30px 10px;
      .box{
        margin: 0;
        position: relative;
      }
    }
  }

  @media (min-width: 768px) {
    .list_feature{
      display: grid;
      gap: 2%;
      grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
      .box{
        display: grid;
        grid-template-rows: subgrid;
        row-gap: 0;
        grid-row: span 3;
        

        h3{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    .list_feature{
      gap: 0 30px;
      grid-template-columns: repeat(auto-fit, minmax(calc((100% - 60px) / 3), 1fr));
      .box{
        padding: 50px 15px 15px;
        .num{
          left: 20px;
        }
      }
    }
  }
}

.sec_2{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 50%;
    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 100%, 0 30%);
    background: #f8ffc9;
    z-index: -1;
  }
  .name{
    @include fontsize(22);
  }

  @media (min-width: 768px) {
    &:before{
      height: 380px;
      clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 100%, 0 calc(100% - 130px));
    }
    .row{
      .col{
        width: 49%;
      }
    }
  }

  @media (min-width: 1024px) {
    .row{
      .col{
        &__img{
          width: 533px;
        }
        &__text{
          width: calc(98% - 533px);
          .name{
            margin: 35px 0;
          }
        }
      }
    }
  }
}

.sec_3{
  background: #f8ffc9;
}

.sec_4{
  background: url("/wp-content/uploads/bg_img001.png");
  .row{
    &__1{
      .col{
        .circle{
          background: linear-gradient(150deg, #f4f9ea 55%, #ecf3df 55%, #ecf3df 100%);
          border-radius: 50%;
          width: 100%;
          height: 0;
          padding-top: 100%;
          position: relative;
          border: 3px solid $color_primary;
          margin-bottom: 10px;
          p{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: clamp(20px, 2.5vw, 25px);
            text-align: center;
            width: 100%;
            line-height: calc(35 / 25);
            font-weight: bold;
            margin: 0;
            color: $color_primary;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .row{
      &__1{
        .col{
          position: relative;
          &:not(:last-child){
            padding-bottom: 50px;
            margin-bottom: 25px;
            &:after{
              position: absolute;
              content: "";
              background: $color_primary;
              width: 40px;
              height: 20px;
              margin: auto;
              left: 0;
              right: 0;
              bottom: 0;
              clip-path: polygon(0 0, 50% 100%, 100% 0);
            }
          }
          .circle{
            width: 220px;
            padding-top: 220px;
            margin-inline: auto;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .row{
      &__1{
        gap: 20px;
        .col{
          width: calc((100% - 60px) / 4);
          &:not(:last-child){
            .circle{
              position: relative;
              &:after{
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: -20px;
                width: 13px;
                height: 37px;
                background: $color_primary;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .row{
      &__1{
        gap: 40px;
        .col{
          width: calc((100% - 120px) / 4);
          &:not(:last-child){
            .circle{
              &:after{
                right: -31px;
              }
            }
          }
        }
      }
    }
  }
}

.sec_news{
  &.bg{
    background: #f8ffc9;
  }
  .news{
    .heading{
      h2{
        margin: 0 auto 1em;
      }
    }
    .content{
      dl{
        dt{
          color: $color_primary;
          .badge_category{
            @include fontsize(14);
            padding: 2px 5px;
            width: 70px;
            display: none;
          }
        }
        dd{
          border-color: #bbc7a2;
          a{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            // padding-right: 1.5em;
            color: $color_text;
            &:after {
              // content: "";
              display: block;
              position: absolute;
              bottom: 49%;
              right: 2%;
              margin: auto;
              width: 20px;
              height: 3px;
              border-bottom: 1px solid $color_primary;
              border-right: 1px solid $color_primary;
              transform: skew(45deg);
              transition: 0.2s;
            }

            &:hover{
              color: $color_secondary;
              &:after{
                right: 1%;
              }
            }
          }
        }
      }
    }
  }

  .button{
    max-width: 350px;
  }

  @media(min-width: 768px){
    .news {
      > div{
        display: block;
        .heading,.content{
          width: 100%;
        }

        .content{
          padding: 0;
          dl{
            max-height: 180px;
            dd{
              a{
                padding-right: 2em;
              }
            }
          }
        }
      }
    }

    @media(max-width: 999px){
      .news{
        >div{
          .content{
            dl{
              dt{
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  @media(min-width: 1000px){
    .news{
      > div{
        .content{
          dl{
            @include fontsize(16);
            dt{
              float: left;
              clear: both;
              width: 115px;
              margin: 0;
              padding: 1em 0;
              +dd{
                padding-left: 115px;
              }
            }
            dd{
              padding: 1em 0;
            }
          }
        }
      }
    }
  }
}

.sec_7 {
  background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center/cover;
  .sha-name{
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
  }

  .row{
    &:not(:last-child){
      margin-bottom: 50px;
    }
    &__2{
      .col{
        &__text{
          p{
            &:not(:last-child){
              border-bottom: 1px solid #bbc7a2;
              padding-bottom: 15px;
              margin-bottom: 15px;
            }

            &.tel{
              line-height: 1;
              margin-bottom: clamp(20px, 3vw, 30px);
            }
          }
        }
      }
    }

    &__3{
      .box{
        .txt{
          display: flex;
          align-items: center;
          justify-content: center;
          height: clamp(150px, 17vw, 173px);
          text-align: center;
          padding: clamp(20px, 4vw, 60px);
          font-weight: bold;
        }
        &__tel{
          border: 2px solid $color_primary;
          background: white;
          color: $color_primary;
          .txt1{
            font-size: clamp(18px, 2.2vw, 22px);
            display: block;
            border-bottom: 1px solid;
            padding-bottom: 5px;
            margin-bottom: 10px;
          }
        }
        &__contact{
          a{
            text-decoration: none;
            position: relative;
            background: $color_secondary;
            color: white;
            transition: all .3s;
            &:after{
              content: "";
              position: absolute;
              right: 10px;
              top: 0;
              bottom: 0;
              margin: auto;
              width: 30px;
              height: 6px;
              border-bottom: 1px solid white;
              border-right: 1px solid white;
              transform: skew(45deg);
            }
            .txt1{
              font-size: clamp(20px, 2.6vw, 26px);
              display: block;
              line-height: 1.6;
            }

            &:hover{
              background: $color_primary;
            }
          }
        }
      }
    }

    &__4{
      p{
        a{
          background: #1877f2;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 232px;
          height: 70px;
          color: white;
          text-decoration: none;
          margin-inline: auto;
          position: relative;
          transition: all .3s;
          &:after{
            content: "";
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 15px;
            height: 3px;
            border-bottom: 1px solid white;
            border-right: 1px solid white;
            transform: skew(45deg);
            transition: all .3s;
          }

          &:hover{
            background: darken(#1877f2, 10%);
            &:after{
              right: 7px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px){
    .row{
      &__2{
        .col{
          &:not(:last-child){
            margin-bottom: 30px;
          }
        }
      }

      &__3{
        .box{
          &:not(:last-child){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  @media (min-width: 768px){
    .row{
      &__3{
        .box{
          width: 49%;
        }
      }
    }
  }
}

.top_pickUp {
  .icon_wrapper {
    width: 130px;
    margin: 0 auto 1em;
    background: $color_primary;
    height: 0;
    padding-top: 130px;
    border-radius: 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  h3 {
    padding: 0;

    &:after {
      content: none;
    }
  }
}
